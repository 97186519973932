var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultDialog",
    { on: { close: _vm.close } },
    [
      !_vm.isLoading
        ? _c("template", { slot: "header" }, [
            _c(
              "h2",
              { staticClass: "dialog-title" },
              [
                !_vm.event
                  ? [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.selectedType
                              ? _vm.eventTypeLookup[_vm.selectedType].create
                              : "Afspraak aanmaken"
                          ) +
                          " "
                      )
                    ]
                  : _vm._e(),
                _vm.event
                  ? [
                      _vm._v(
                        _vm._s(
                          _vm.selectedType
                            ? _vm.eventTypeLookup[_vm.selectedType].edit
                            : "Afspraak aanpassen"
                        )
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        : _vm._e(),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isLoading
            ? _c("LoaderCard", {
                attrs: { flat: "", type: "spinner--center", minHeight: "250px" }
              })
            : _vm._e(),
          !_vm.isLoading
            ? [
                _c(
                  "v-layout",
                  { attrs: { wrap: "", "pb-2": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs8: "", "pr-4": "" } },
                      [
                        _c(
                          "v-menu",
                          {
                            ref: "dialog",
                            attrs: {
                              "close-on-content-click": false,
                              lazy: "",
                              "nudge-right": "100",
                              "full-width": "",
                              "max-width": "290px",
                              "min-width": "290px"
                            },
                            model: {
                              value: _vm.isEditingStartDate,
                              callback: function($$v) {
                                _vm.isEditingStartDate = $$v
                              },
                              expression: "isEditingStartDate"
                            }
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "input input-date",
                              attrs: {
                                slot: "activator",
                                placeholder: "DD-MM-JJJJ",
                                label: "Startdatum afspraak",
                                "error-messages": _vm.dateErrorMessage,
                                "hide-details": "",
                                "prepend-icon": "today"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.dateFromTextField($event, "start")
                                }
                              },
                              slot: "activator",
                              model: {
                                value: _vm.startdateFormatted,
                                callback: function($$v) {
                                  _vm.startdateFormatted = $$v
                                },
                                expression: "startdateFormatted"
                              }
                            }),
                            _c("v-date-picker", {
                              ref: "picker",
                              attrs: {
                                locale: "nl-nl",
                                min: "1910-01-01",
                                color: "#837f16",
                                "first-day-of-week": "1"
                              },
                              on: {
                                change: function($event) {
                                  _vm.isEditingStartDate = false
                                }
                              },
                              model: {
                                value: _vm.startDate,
                                callback: function($$v) {
                                  _vm.startDate = $$v
                                },
                                expression: "startDate"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs4: "" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: "Regular",
                            "single-line": "",
                            type: "time"
                          },
                          model: {
                            value: _vm.startTime,
                            callback: function($$v) {
                              _vm.startTime = $$v
                            },
                            expression: "startTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  { attrs: { wrap: "", "pb-4": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs8: "", "pr-4": "" } },
                      [
                        _c(
                          "v-menu",
                          {
                            ref: "dialog",
                            attrs: {
                              "close-on-content-click": false,
                              lazy: "",
                              "nudge-right": "100",
                              "full-width": "",
                              "max-width": "290px",
                              "min-width": "290px"
                            },
                            model: {
                              value: _vm.isEditingEndDate,
                              callback: function($$v) {
                                _vm.isEditingEndDate = $$v
                              },
                              expression: "isEditingEndDate"
                            }
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "input input-date",
                              attrs: {
                                slot: "activator",
                                placeholder: "DD-MM-JJJJ",
                                label: "Einddatum afspraak",
                                "error-messages": _vm.dateErrorMessage,
                                "hide-details": "",
                                "prepend-icon": "today"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.dateFromTextField($event, "end")
                                }
                              },
                              slot: "activator",
                              model: {
                                value: _vm.enddateFormatted,
                                callback: function($$v) {
                                  _vm.enddateFormatted = $$v
                                },
                                expression: "enddateFormatted"
                              }
                            }),
                            _c("v-date-picker", {
                              ref: "picker",
                              attrs: {
                                locale: "nl-nl",
                                min: "1910-01-01",
                                color: "#837f16",
                                "first-day-of-week": "1"
                              },
                              on: {
                                change: function($event) {
                                  _vm.isEditingEndDate = false
                                }
                              },
                              model: {
                                value: _vm.endDate,
                                callback: function($$v) {
                                  _vm.endDate = $$v
                                },
                                expression: "endDate"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs4: "" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: "Regular",
                            "single-line": "",
                            type: "time"
                          },
                          model: {
                            value: _vm.endTime,
                            callback: function($$v) {
                              _vm.endTime = $$v
                            },
                            expression: "endTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                !_vm.event
                  ? _c(
                      "v-layout",
                      { attrs: { wrap: "", "align-baseline": "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs6: "", "pr-2": "" } },
                          [
                            _c("v-select", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                items: _vm.repeatOptions,
                                "item-value": "value",
                                "item-text": "name",
                                label: "Herhalen",
                                "prepend-icon": "repeat"
                              },
                              model: {
                                value: _vm.repeatOption,
                                callback: function($$v) {
                                  _vm.repeatOption = $$v
                                },
                                expression: "repeatOption"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs6: "", "pl-2": "" } },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: "dialog",
                                attrs: {
                                  "close-on-content-click": false,
                                  lazy: "",
                                  "nudge-right": "100",
                                  "full-width": "",
                                  "max-width": "290px",
                                  "min-width": "290px"
                                }
                              },
                              [
                                _vm.repeatOption !== "none"
                                  ? _c("v-text-field", {
                                      staticClass: "input pa-0 ma-0 input-date",
                                      attrs: {
                                        slot: "activator",
                                        label: "Einddatum herhaling",
                                        "prepend-icon": "today",
                                        placeholder: "DD-MM-YYYY",
                                        clearable: "",
                                        "error-messages":
                                          _vm.repeatEndDate.errorMessage
                                      },
                                      on: {
                                        "click:clear": function($event) {
                                          return _vm.repeatEndDate.clear()
                                        }
                                      },
                                      slot: "activator",
                                      model: {
                                        value: _vm.repeatEndDate.formattedDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.repeatEndDate,
                                            "formattedDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "repeatEndDate.formattedDate"
                                      }
                                    })
                                  : _vm._e(),
                                _c("v-date-picker", {
                                  ref: "picker",
                                  attrs: {
                                    "first-day-of-week": "1",
                                    locale: "nl-nl",
                                    min: "1910-01-01",
                                    color: "#837f16"
                                  },
                                  model: {
                                    value: _vm.repeatEndDate.selectedDate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.repeatEndDate,
                                        "selectedDate",
                                        $$v
                                      )
                                    },
                                    expression: "repeatEndDate.selectedDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.repeatOption !== "none"
                          ? _c(
                              "v-flex",
                              { attrs: { xs12: "", "mt-3": "" } },
                              [
                                _c("v-select", {
                                  staticClass: "pa-0 ma-0",
                                  attrs: {
                                    items: _vm.conflictOptions,
                                    "item-value": "value",
                                    "item-text": "name",
                                    label:
                                      "Conflict oplossen bij dubbele afspraak",
                                    "prepend-icon": "warning"
                                  },
                                  model: {
                                    value: _vm.conflictOption,
                                    callback: function($$v) {
                                      _vm.conflictOption = $$v
                                    },
                                    expression: "conflictOption"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-layout",
                  { attrs: { wrap: "", "pb-4": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-textarea", {
                          attrs: { name: "note", label: "Notitie" },
                          model: {
                            value: _vm.note,
                            callback: function($$v) {
                              _vm.note = $$v
                            },
                            expression: "note"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.event
                  ? _c(
                      "v-layout",
                      { attrs: { wrap: "", "mb-4": "" } },
                      [
                        _c(
                          "v-flex",
                          [
                            _c(
                              "v-radio-group",
                              {
                                attrs: { "hide-details": "" },
                                model: {
                                  value: _vm.sequenceProccesing,
                                  callback: function($$v) {
                                    _vm.sequenceProccesing = $$v
                                  },
                                  expression: "sequenceProccesing"
                                }
                              },
                              [
                                _c(
                                  "v-radio",
                                  {
                                    attrs: {
                                      value: "single",
                                      color: "secondary"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label"
                                      },
                                      [_vm._v("Deze afspraak")]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-radio",
                                  {
                                    attrs: {
                                      value: "hereafter",
                                      color: "secondary"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label"
                                      },
                                      [_vm._v("Deze en volgende afspraken")]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-radio",
                                  {
                                    attrs: { value: "all", color: "secondary" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label"
                                      },
                                      [_vm._v("Alle afspraken")]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.event
                  ? _c(
                      "v-layout",
                      { attrs: { wrap: "", "pb-4": "" } },
                      [
                        _c("v-flex", { attrs: { xs12: "" } }, [
                          _c("h2", { staticClass: "dialog-title" }, [
                            _vm._v("Extra gebruikers:")
                          ]),
                          _c("p", { staticClass: "fadedText" }, [
                            _vm._v(
                              "Ook toevoegen in de agenda van de volgende gebruikers"
                            )
                          ])
                        ]),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-autocomplete", {
                              staticClass: "with-border",
                              attrs: {
                                items: _vm.users,
                                "item-value": "uuid",
                                "item-text": "name",
                                label: "Medewerkers",
                                multiple: ""
                              },
                              model: {
                                value: _vm.selectedUsers,
                                callback: function($$v) {
                                  _vm.selectedUsers = $$v
                                },
                                expression: "selectedUsers"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedType === "community_center"
                  ? _c(
                      "v-layout",
                      { attrs: { wrap: "", "pb-4": "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.locations,
                                label: "Adres",
                                "item-value": "uuid",
                                "item-text": "label",
                                clearable: ""
                              },
                              model: {
                                value: _vm.selectedLocation,
                                callback: function($$v) {
                                  _vm.selectedLocation = $$v
                                },
                                expression: "selectedLocation"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "template",
        { slot: "actions" },
        [
          !_vm.isLoading
            ? _c(
                "v-layout",
                { attrs: { "justify-end": "", "align-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "fadedText link mr-3",
                          on: { click: _vm.close }
                        },
                        [_vm._v("Annuleren")]
                      ),
                      _vm.event
                        ? _c(
                            "MiButton",
                            {
                              staticClass: "mr-4",
                              attrs: { color: "warning" },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteEvent($event)
                                }
                              }
                            },
                            [_vm._v("Verwijderen")]
                          )
                        : _vm._e(),
                      _c(
                        "MiButton",
                        {
                          attrs: { color: "success", disabled: !_vm.canSave },
                          nativeOn: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.createOrUpdate($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.event ? "Aanpassen" : "Inplannen") +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }