import { Planner } from '@/support/Planner';
import { cloneDeep, debounce } from 'lodash';
import { renderCustomEvent, renderSimpleEvent } from '@/support/CalendarEventRenderFunctions';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DateTime, Settings } from 'luxon';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Organization } from '@/models/Organization';
import { User, CalendarUser, userRoles, UserRole } from '@/models/User';
import CreateEventDialog from '@/views/Planning/AvailabilityPlanner/CreateEventDialog/CreateEventDialog.vue';
import { slotDuration, slotLabelFormat, columnsHeaderFormat, calendarPlugins } from '@/support/FullcalendarSettings';
import { firstDayOfWeek, lastDayOfWeek, setFormattedDatePickerValue, dateErrorMessage, isValidDate } from '@/support/String';
import { Event as EventModel, EventType, EventTypeLabels } from '@/models/Event';
import { Options } from '@/components/mi-dialog/MiDialog';
import { Report } from '@/models/Report';
import { ReportTypes } from '@/support/ReportTypes';

@Component<AvailabilityPlanner>({
  components: {
    CreateEventDialog,
  },
})
export default class AvailabilityPlanner extends Planner {
  public $pageTitle = 'Agenda\'s Deskundigen en ZBs';

  protected users: CalendarUser[] = [];

  protected preselectedUser: CalendarUser | null = null;

  protected events: EventModel[] = [];

  protected reports: Report[] = [];

  protected selectedUser: CalendarUser | null = null;

  protected organizations: Organization[] | null = null;

  protected selectedToggle: string | null = null;

  protected selectedEvent: EventModel | null = null;

  protected selectedStartDate: DateTime | null = null;

  protected selectedEndDate: DateTime | null = null;

  protected selectedReport: string | null = null;

  protected date: string | DateTime = '';

  protected activeOrganization = '';

  protected clickCount = 0;

  protected clickTimer: NodeJS.Timeout | null = null;

  protected selectedUserId = '';

  protected expertSearch = '';

  protected isSaving = false;

  protected isShowingEventDialog = false;

  protected debouncedSearch: Function = debounce(this.handleSearch, 300);

  protected isLoading = true;

  protected userRoles = userRoles;

  public mounted() {
    Settings.defaultLocale = 'nl';
    this.date = DateTime.local().toFormat('yyyy-LL-dd');
    this.emitBreadcrumb();
    this.initialize();
  }

  protected initialize() {
    if (this.$store.state.isServiceOrganization) {
      this.getOrganizations();
    } else {
      this.activeOrganization = this.$store.state.Auth.organization.id;
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
      this.isLoading = false;
    }

    if (this.$route.query && this.$route.query.date) {
      this.date = this.$route.query.date as string;
    }

    if (this.$route.query && this.$route.query.user && this.$route.query.organization) {
      this.findUser(this.$route.query.user as string, this.$route.query.organization as string);
    }
  }

  /**
   *
   * Fetches the Orginazations from the API, filter only the Orgainzation of type expert
   * set the activeOrginazation to the first
   *
   * @protected
   * @memberof AvailabilityPlanner
   */
  protected getOrganizations() {
    new Organization()
      .all()
      .then((organizations: Organization[]) => {
        if (organizations && organizations.length && organizations[0].name) {
          this.activeOrganization = this.$route.query && this.$route.query.organization
            ? this.$route.query.organization as string
            : organizations[0].id as string;

          this.organizations = organizations;
          this.getUsers();
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   *
   * Parse an array of User Models to an array of CalendarUsers
   *
   * @protected
   * @param {User[]} users
   * @returns {CalendarUser[]}
   * @memberof AvailabilityPlanner
   */
  protected parseUsersToCalendarUsers(users: User[]): CalendarUser[] {
    const calendarUsers:CalendarUser[] = [];

    users.forEach((user: User) => {
      const calendarUser: CalendarUser = user.parseToCalendarUser();
      calendarUser.businessHours = user.parseScheduleToBusinessHours();
      calendarUser.organization = this.activeOrganization;
      calendarUsers.push(calendarUser);
    });

    return calendarUsers;
  }

  /**
   *
   * Fetch the users from the API to fill the expert autocomplete
   *
   * @protected
   * @memberof AvailabilityPlanner
   */
  protected getUsers() {
    new User()
      .dmz(this.activeOrganization)
      .include(['schedules'])
      .filter('search', this.expertSearch)
      .all()
      .then((users: User[]) => {
        this.users = this.parseUsersToCalendarUsers(users);
        if (this.preselectedUser && this.preselectedUser.organization === this.activeOrganization) {
          this.users.push(this.preselectedUser);
          this.selectedUserId = this.preselectedUser.id || '';
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected getUser() {
    if (! this.selectedUserId) {
      return;
    }

    new User()
      .dmz(this.activeOrganization)
      .include(['schedules'])
      .filter('schedule_date_spoof', this.date)
      .find(this.selectedUserId)
      .then(async (user: User) => {
        this.selectedUser = user.parseToCalendarUser();
        this.selectedUser.businessHours = user.parseScheduleToBusinessHours();
        this.setCalendarView();
        const reports = await this.getReports(this.reportsFilter);
        if (! reports) {
          return;
        }

        reports.forEach((report: Report) => {
          if (this.isAllDayEvent(this.events, report, this.selectedUser || undefined) && this.selectedUser) {
            this.selectedUser.events.push(this.generateAllDayEventFromReport(report, {
              extendedProps: { type: 'all-day' },
            }));
          }
        });
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected findUser(userId: string, organizationId: string) {
    new User()
      .dmz(organizationId)
      .filter('schedule_date_spoof', this.date)
      .include(['schedules'])
      .find(userId)
      .then((user: User) => {
        if (user) {
          this.preselectedUser = user.parseToCalendarUser();
          this.preselectedUser.businessHours = user.parseScheduleToBusinessHours();
          this.preselectedUser.organization = this.$route.query && this.$route.query.organization ? this.$route.query.organization as string : '';
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected handleSearch(query: string) {
    if (! query) {
      return;
    }

    this.getUsers();
  }

  protected resetUser() {
    this.selectedUserId = '';
    this.expertSearch = '';
    this.selectedUser = null;
    this.getUsers();
  }

  protected switchCalendarView() {
    const foundUser = this.users.find((user: CalendarUser) => user.id === this.selectedUserId);

    this.selectedUser = foundUser || null;

    this.$nextTick(() => {
      this.setCalendarView();
    });
  }

  protected setCalendarView() {
    this.getEvents();
    const calendar = this.$refs.fullcalendar as any;
    if (calendar && calendar.length) {
      calendar[0].getApi().changeView('timeGridWeek');
      calendar[0].getApi().gotoDate(this.date);
    }
  }

  protected get slotDuration() {
    return slotDuration;
  }

  protected get eventTypeLabels() {
    return EventTypeLabels;
  }

  protected get slotLabelFormat() {
    return slotLabelFormat;
  }

  protected get columnsHeaderFormat() {
    return columnsHeaderFormat;
  }

  protected get calendarPlugins() {
    return calendarPlugins;
  }

  protected get dialogOptionsCreateError(): Options {
    return {
      title: 'Geen afspraaktype geselecteerd',
      text: 'Om een afspraak in te plannen selecteer dan eerst een status in de werkbalk.',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Sluiten',
          color: 'success',
        },
      },
    };
  }

  protected get dialogOptionsUpdateError(): Options {
    return {
      title: 'Geen rechten',
      text: 'U heeft geen rechten om deze afspraak te wijzigen.',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Sluiten',
          color: 'success',
        },
      },
    };
  }

  protected get editAppointmentDialogOptions(): Options {
    return {
      title: 'Dossier afspraak niet aan te passen',
      text: 'Je kunt hier geen dossier afpraak aanpassen, je kunt dit doen op de pagina van dit dossier, onder de tab \'Planning\'.',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Naar dossier pagina',
          color: 'success',
          action: () => {
            if (! this.selectedReport) {
              this.$store.dispatch('closeDialog');
              return;
            }
            window.open(`/reports/${this.selectedReport}`, '_blank');
            this.selectedEvent = null;
          },
        },
        cancel: {
          text: 'Sluiten',
          color: 'success',
        },
      },
    };
  }

  protected handleTabChange(organization: string) {
    this.activeOrganization = organization;
    this.resetUser();
  }

  protected goToPreviousWeek() {
    const calendars = Object.keys(this.$refs).filter((key: string) => key.includes('fullcalendar'));

    calendars.forEach((key: string) => {
      const calendar = this.$refs[key] as any;
      if (calendar && calendar.length && this.date) {
        const previousWeek = DateTime.fromSQL((this.date as string)).minus({ weeks: 1 });
        this.date = previousWeek.toFormat('yyyy-MM-dd');
        calendar[0].getApi().gotoDate(this.date);
        this.getUser();
      }
    });
  }

  protected goToNextWeek() {
    const calendars = Object.keys(this.$refs).filter((key: string) => key.includes('fullcalendar'));

    calendars.forEach((key: string) => {
      const calendar = this.$refs[key] as any;
      if (calendar && calendar.length && this.date) {
        const nextWeek = DateTime.fromSQL((this.date as string)).plus({ weeks: 1 });
        this.date = nextWeek.toFormat('yyyy-MM-dd');
        calendar[0].getApi().gotoDate(this.date);
        this.getUser();
      }
    });
  }

  protected goToToday() {
    const calendars = Object.keys(this.$refs).filter((key: string) => key.includes('fullcalendar'));

    calendars.forEach((key: string) => {
      const calendar = this.$refs[key] as any;
      if (calendar && calendar.length && this.date) {
        this.date = DateTime.local().toFormat('yyyy-LL-dd');
        calendar[0].getApi().gotoDate(this.date);
        this.getUser();
      }
    });
  }

  protected goToDate() {
    const calendars = Object.keys(this.$refs).filter((key: string) => key.includes('fullcalendar'));

    calendars.forEach((key: string) => {
      const calendar = this.$refs[key] as any;
      if (calendar && calendar.length && this.date) {
        calendar[0].getApi().gotoDate(this.date);
        this.getUser();
      }
    });
  }

  protected get weekNumber() {
    const date = DateTime.fromSQL(this.date as string);
    return date ? date.weekNumber : '';
  }

  protected get headerDate() {
    const date = DateTime.fromSQL(this.date as string);
    return date ? `(${firstDayOfWeek(date).toFormat('dd-LL-yyyy')} t/m ${lastDayOfWeek(date).toFormat('dd-LL-yyyy')})` : '';
  }

  /**
   * Get the events of status busy or appointment for the available users
   *
   * @protected
   * @memberof AvailabilityPlanner
   */
  protected getEvents() {
    this.events = [];

    new EventModel()
      .dmz(this.activeOrganization)
      .include(['report', 'is_mediator_present', 'address'])
      .filter(this.eventsFilter)
      .all()
      .then((events: EventModel[]) => {
        this.events = events;
        if (events.length && this.selectedUser) {
          this.selectedUser.events = [];
          this.parseEvents(events);
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  private get reportsFilter() {
    const filter: {[key: string]: string|string[]} = {};

    if (this.date) {
      const dateTime = DateTime.fromSQL(this.date as string);
      filter.week = firstDayOfWeek(dateTime).toFormat('dd-LL-yyyy');
    }

    if (this.selectedUserId) {
      filter.mediators = [this.selectedUserId];
    }

    return filter;
  }

  protected parseEvents(events: EventModel[]) {
    events.forEach((event: EventModel) => {
      const user = this.selectedUser;

      if (user) {
        const fullcalendarEvent: {[key: string]: any} | null = event.fullcalendarEvent;
        if (! fullcalendarEvent) { return; }
        fullcalendarEvent.extendedProps = {
          type: event.type,
          appointment_type: event.appointment_type,
          appointment_group: event.appointment_group,
          user: {
            name: user.name,
            id: user.id,
          },
          organization_id: user.organization,
          report: event.report,
          note: event.note,
          is_mediator_present: event.is_mediator_present,
          confirm_email_to_application: event.confirm_email_to_application,
          address: event.address,
          isIMSEvent: event.isIMSEvent,
        };

        if (event.type !== 'appointment') {
          fullcalendarEvent.title = this.eventTypeLabels[event.type as string];
        }

        user.events.push(fullcalendarEvent);
      }
    });
  }

  protected get canEditCalendar() {
    return this.$store.state.isServiceOrganization && ! this.isServiceLoket && ! this.$store.state.Auth.hasRole('planning');
  }

  protected renderEvent(eventInfo: any) {
    if (eventInfo && eventInfo.event && eventInfo.event.extendedProps && eventInfo.event.extendedProps.type === 'appointment') {
      renderCustomEvent(eventInfo);
    } else {
      renderSimpleEvent(eventInfo);
    }
  }

  protected renderEventToCalendar(event: EventModel) {}

  protected createEvent(event: any) {
    if (! this.canEditCalendar) {
      return;
    }

    if (! this.selectedToggle) {
      this.$store.dispatch('openDialog', this.dialogOptionsCreateError);
      return;
    }

    this.selectedStartDate = DateTime.fromJSDate(event.start);
    this.selectedEndDate = DateTime.fromJSDate(event.end);
    this.isShowingEventDialog = true;
  }

  protected updateEvent(eventInfo: any) {
    if (! this.canEditCalendar) {
      return;
    }

    const event = eventInfo.event;
    if (! event) {
      return;
    }

    if (event.extendedProps && event.extendedProps.type && event.extendedProps.type === 'appointment') {
      eventInfo.revert();
      this.selectedReport = event.extendedProps.report.id;
      this.$store.dispatch('openDialog', this.editAppointmentDialogOptions);
      return;
    }

    if (! this.isAdminOrManager && (event?.extendedProps?.type === 'loss_of_non_residential_property_value')) {
      eventInfo.revert();
      this.$store.dispatch('openDialog', this.dialogOptionsUpdateError);
      return;
    }

    if (! this.isAdminOrManager && (event?.extendedProps?.type === 'manure_cellar')) {
      eventInfo.revert();
      this.$store.dispatch('openDialog', this.dialogOptionsUpdateError);
      return;
    }

    const payload: {[key:string]: string} = {
      starts_at: DateTime.fromJSDate(event.start).toFormat('yyyy-LL-dd HH:mm:ss'),
      ends_at: DateTime.fromJSDate(event.end).toFormat('yyyy-LL-dd HH:mm:ss'),
    };

    new EventModel({ id: event.id })
      .dmz(this.activeOrganization)
      .update(payload)
      .then(() => {
        this.getEvents();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  private get eventsFilter() {
    const filter: {[key: string]: any} = {
      types: [
        EventType.APPOINTMENT,
        EventType.SICK,
        EventType.HOLIDAY,
        EventType.MEETING,
        EventType.COMMUNITYCENTER,
        EventType.HOUSEVISIT,
        EventType.SPEAKINGROOM,
        EventType.LOSSOFNONRESIDENTIALPROPERTYVALUE,
        EventType.MANURECELLAR,
        EventType.OTHER,
      ],
      not_cancelled: true,
      user: this.selectedUserId,
    };

    if (this.date) {
      filter.period = [
        firstDayOfWeek(DateTime.fromFormat(this.date as string, 'yyyy-LL-dd')).toFormat('yyyy-LL-dd'),
        lastDayOfWeek(DateTime.fromFormat(this.date as string, 'yyyy-LL-dd')).toFormat('yyyy-LL-dd'),
      ];
    }

    return filter;
  }

  protected selectToggle(type: string) {
    if (this.selectedToggle === type) {
      this.selectedToggle = null;
      return;
    }

    this.selectedToggle = type;
  }

  protected toggleToggleLossOfNonResidentialPropertyValue(): void {
    if (! this.isAdminOrManager) {
      return;
    }

    this.selectToggle('loss_of_non_residential_property_value');
  }

  protected toggleToggleManureCellar(): void {
    if (! this.isAdminOrManager) {
      return;
    }

    this.selectToggle('manure_cellar');
  }

  protected handleEventClick(eventInfo: any) {
    // if (!this.canEditCalendar) {
    //   return;
    // }

    if (eventInfo.event?.allDay && eventInfo.event?.id) {
      this.$router.push(`/reports/${eventInfo.event.id}`);
      return;
    }

    this.clickCount += 1;

    if (this.clickCount === 1) {
      this.clickTimer = setTimeout(() => {
        this.clickCount = 0;
      }, 200);
    } else if (this.clickCount === 2) {
      clearTimeout(this.clickTimer as any);
      this.clickCount = 0;
      this.handleDoubleClick(eventInfo);
    }
  }

  protected handleDoubleClick(eventInfo: any) {
    if (! this.canEditCalendar) {
      window.open(`${window.location.origin}/reports/${eventInfo.event.extendedProps.report.id}`);
      return;
    }

    if (eventInfo.event.extendedProps && eventInfo.event.extendedProps.type && (eventInfo.event.extendedProps.type === 'appointment')) {
      this.selectedReport = eventInfo.event.extendedProps.report.id;
      this.$store.dispatch('openDialog', this.editAppointmentDialogOptions);
      return;
    }

    if (eventInfo?.event?.extendedProps?.type === 'all-day') {
      this.selectedReport = eventInfo.event.id;
      this.$store.dispatch('openDialog', this.editAppointmentDialogOptions);
      return;
    }

    const event: any = this.events.find((currentEvent: EventModel) => eventInfo.event && eventInfo.event.id && eventInfo.event.id === currentEvent.id);

    if (! event) {
      return;
    }

    this.selectedEvent = event;
    this.isShowingEventDialog = true;
  }

  protected handleEventCreatedOrUpdated(event: EventModel) {
    this.getEvents();
  }

  protected canSelectOverlap(event: any) {
    return !! event.allDay;
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Agenda\'s Deskundigen en ZBs' },
        ],
      });
  }

  // Date
  protected isEditingDate = false;

  protected dateFormatted: string | null = null;

  protected dateErrorMessage = '';

  protected formatDateFromDatePicker() {
    if (this.date) {
      this.dateFormatted = setFormattedDatePickerValue(this.date as string, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.dateErrorMessage = ! isValidDate(this.dateFormatted) ? dateErrorMessage : '';
    }
  }

  protected formatDateFromTextField(value: string) {
    this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.date = setFormattedDatePickerValue(value);
  }

  @Watch('date')
  protected dateChanged() {
    this.formatDateFromDatePicker();
  }

  @Watch('isEditingDate')
  protected isEditingDateChanged() {
    if (! this.isEditingDate) {
      this.formatDateFromDatePicker();
    }
  }

  // Getters
  protected get defaultDate() {
    return this.date as string;
  }

  protected set defaultDate(date: string) {
    this.date = date;
  }

  protected get isServiceLoket() {
    return this.$store.state.Auth.hasRole('serviceloket');
  }

  protected get isAdminOrManager(): boolean {
    return this.$store.state.Auth.hasRole([UserRole.ADMIN, UserRole.MANAGER]) && this.$store.state.Auth.is_planner;
  }

  protected get lossOfNonResidetialPropertyValueClass() {
    return {
      selected: this.selectedToggle === 'loss_of_non_residential_property_value',
      'toolbar__toggle--loss_of_non_residential_property_value': this.isAdminOrManager,
      'toolbar__toggle--disabled': ! this.isAdminOrManager,
    };
  }

  protected get manureCallerClass() {
    return {
      selected: this.selectedToggle === 'manure_cellar',
      'toolbar__toggle--manure_cellar': this.isAdminOrManager,
      'toolbar__toggle--disabled': ! this.isAdminOrManager,
    };
  }

  // Watchers
  @Watch('isShowingEventDialog')
  protected isShowingEventDialogChanged() {
    if (! this.isShowingEventDialog) {
      this.selectedEvent = null;
    }
  }

  @Watch('selectedUserId')
  protected selectedExpertIdChanged() {
    this.getUser();
  }

  @Watch('expertSearch')
  protected expertSearchChanged(query: string) {
    if (query && query.length) {
      this.debouncedSearch(query);
    }
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
