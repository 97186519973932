var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$store.state.isServiceOrganization
                      ? "Agenda's Deskundigen en ZBs"
                      : "Agenda's Deskundigen"
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeOrganization,
                    callback: function($$v) {
                      _vm.activeOrganization = $$v
                    },
                    expression: "activeOrganization"
                  }
                },
                [
                  _vm._l(_vm.organizations, function(organization, index) {
                    return [
                      _c(
                        "v-tab",
                        {
                          key: "planning-tab-" + index,
                          staticClass: "tab__filled",
                          class: {
                            active: _vm.activeOrganization === organization.id
                          },
                          attrs: { href: "#" + organization.id },
                          on: {
                            click: function($event) {
                              return _vm.handleTabChange(organization.id)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(organization.name) + " ")]
                      )
                    ]
                  }),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeOrganization,
                        callback: function($$v) {
                          _vm.activeOrganization = $$v
                        },
                        expression: "activeOrganization"
                      }
                    },
                    [
                      _vm._l(_vm.organizations, function(organization) {
                        return [
                          _c(
                            "v-tab-item",
                            {
                              key: "planning-tab-content-" + organization.id,
                              attrs: {
                                lazy: true,
                                transition: false,
                                "reverse-transition": false,
                                value: organization.id
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "elementPanel" },
                                [
                                  _vm.activeOrganization === organization.id
                                    ? _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "justify-start": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  staticClass:
                                                    "filterContainer",
                                                  attrs: { wrap: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass: "mr-4",
                                                      attrs: { shrink: "" }
                                                    },
                                                    [
                                                      _c("v-autocomplete", {
                                                        attrs: {
                                                          items: _vm.users,
                                                          label:
                                                            "Kies een deskundige",
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          "hide-details": "",
                                                          placeholder:
                                                            "Kies een deskundige",
                                                          "search-input":
                                                            _vm.expertSearch,
                                                          "prepend-icon":
                                                            "assignment_ind",
                                                          clearable: "",
                                                          solo: "",
                                                          flat: ""
                                                        },
                                                        on: {
                                                          "click:clear": function(
                                                            $event
                                                          ) {
                                                            return _vm.resetUser()
                                                          },
                                                          "update:searchInput": function(
                                                            $event
                                                          ) {
                                                            _vm.expertSearch = $event
                                                          },
                                                          "update:search-input": function(
                                                            $event
                                                          ) {
                                                            _vm.expertSearch = $event
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedUserId,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.selectedUserId = $$v
                                                          },
                                                          expression:
                                                            "selectedUserId"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm.selectedUser
                                                    ? _c(
                                                        "v-flex",
                                                        {
                                                          attrs: { shrink: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              ref: "dialog",
                                                              refInFor: true,
                                                              attrs: {
                                                                "close-on-content-click": false,
                                                                lazy: "",
                                                                "nudge-right":
                                                                  "100",
                                                                "full-width":
                                                                  "",
                                                                "max-width":
                                                                  "290px",
                                                                "min-width":
                                                                  "290px"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.isEditingDate,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.isEditingDate = $$v
                                                                },
                                                                expression:
                                                                  "isEditingDate"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  staticClass:
                                                                    "input input-date",
                                                                  attrs: {
                                                                    slot:
                                                                      "activator",
                                                                    placeholder:
                                                                      "DD-MM-YYYY",
                                                                    label:
                                                                      "Kies een datum",
                                                                    "error-messages":
                                                                      _vm.dateErrorMessage,
                                                                    "prepend-icon":
                                                                      "today"
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.formatDateFromTextField(
                                                                        $event
                                                                      )
                                                                    },
                                                                    "click:prepend":
                                                                      _vm.goToToday
                                                                  },
                                                                  slot:
                                                                    "activator",
                                                                  model: {
                                                                    value:
                                                                      _vm.dateFormatted,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.dateFormatted = $$v
                                                                    },
                                                                    expression:
                                                                      "dateFormatted"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "v-date-picker",
                                                                {
                                                                  ref: "picker",
                                                                  refInFor: true,
                                                                  attrs: {
                                                                    "first-day-of-week":
                                                                      "1",
                                                                    locale:
                                                                      "nl-nl",
                                                                    min:
                                                                      "1910-01-01",
                                                                    color:
                                                                      "#837f16"
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.isEditingDate = false
                                                                      _vm.goToDate()
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.defaultDate,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.defaultDate = $$v
                                                                    },
                                                                    expression:
                                                                      "defaultDate"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { wrap: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _vm.isLoading
                                                        ? _c("LoaderCard", {
                                                            attrs: {
                                                              flat: "",
                                                              type:
                                                                "spinner--center",
                                                              minHeight: "300px"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  !_vm.selectedUser &&
                                                  !_vm.isLoading
                                                    ? _c(
                                                        "v-flex",
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "no-selection-text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Je hebt nog geen deskundige geselecteerd. Kies een deskundige om zijn/haar agenda te beheren "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.selectedUser &&
                                                  !_vm.isLoading
                                                    ? _c(
                                                        "v-flex",
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "calendarContainer",
                                                                  attrs: {
                                                                    "pa-4": "",
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-layout",
                                                                    [
                                                                      _vm.canEditCalendar
                                                                        ? _c(
                                                                            "v-flex",
                                                                            [
                                                                              _c(
                                                                                "v-layout",
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "toolbar__toggle toolbar__toggle--house_visit",
                                                                                      class: {
                                                                                        selected:
                                                                                          _vm.selectedToggle &&
                                                                                          _vm.selectedToggle ===
                                                                                            "house_visit"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.selectToggle(
                                                                                            "house_visit"
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "house"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " Huisbezoek "
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "toolbar__toggle toolbar__toggle--speaking_room",
                                                                                      class: {
                                                                                        selected:
                                                                                          _vm.selectedToggle &&
                                                                                          _vm.selectedToggle ===
                                                                                            "speaking_room"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.selectToggle(
                                                                                            "speaking_room"
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "meeting_room"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " Spreekkamer dienst "
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "toolbar__toggle toolbar__toggle--meeting",
                                                                                      class: {
                                                                                        selected:
                                                                                          _vm.selectedToggle &&
                                                                                          _vm.selectedToggle ===
                                                                                            "meeting"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.selectToggle(
                                                                                            "meeting"
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "people"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " Overleg "
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "toolbar__toggle toolbar__toggle--community_center",
                                                                                      class: {
                                                                                        selected:
                                                                                          _vm.selectedToggle &&
                                                                                          _vm.selectedToggle ===
                                                                                            "community_center"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.selectToggle(
                                                                                            "community_center"
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "mdi-pillar"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " steunpunt "
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "toolbar__toggle toolbar__toggle--holiday",
                                                                                      class: {
                                                                                        selected:
                                                                                          _vm.selectedToggle &&
                                                                                          _vm.selectedToggle ===
                                                                                            "holiday"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.selectToggle(
                                                                                            "holiday"
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "airplanemode_active"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " Verlof "
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "toolbar__toggle toolbar__toggle--sick",
                                                                                      class: {
                                                                                        selected:
                                                                                          _vm.selectedToggle &&
                                                                                          _vm.selectedToggle ===
                                                                                            "sick"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.selectToggle(
                                                                                            "sick"
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "local_hospital"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " Ziek "
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "toolbar__toggle toolbar__toggle--busy",
                                                                                      class: {
                                                                                        selected:
                                                                                          _vm.selectedToggle &&
                                                                                          _vm.selectedToggle ===
                                                                                            "other"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.selectToggle(
                                                                                            "other"
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "remove_circle"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " Overig "
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "toolbar__toggle",
                                                                                      class:
                                                                                        _vm.lossOfNonResidetialPropertyValueClass,
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.toggleToggleLossOfNonResidentialPropertyValue()
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Waardedaling niet woningen "
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "toolbar__toggle",
                                                                                      class:
                                                                                        _vm.manureCallerClass,
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.toggleToggleManureCellar()
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Mestkelder "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-layout",
                                                                    {
                                                                      attrs: {
                                                                        "justify-space-between":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          staticClass:
                                                                            "flex-starving"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "h2",
                                                                            {
                                                                              staticClass:
                                                                                "elementSubTitle"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .selectedUser
                                                                                    .name
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          staticClass:
                                                                            "flex-starving"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "weekChangeContainer"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "weekChange weekChange__prev",
                                                                                  on: {
                                                                                    click:
                                                                                      _vm.goToPreviousWeek
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " keyboard_arrow_left "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "h2",
                                                                                {
                                                                                  staticClass:
                                                                                    "elementSubTitle elementSubTitle__accent"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Week " +
                                                                                      _vm._s(
                                                                                        _vm.weekNumber
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "header-date"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.headerDate
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "weekChange weekChange__next",
                                                                                  on: {
                                                                                    click:
                                                                                      _vm.goToNextWeek
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " keyboard_arrow_right "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-layout",
                                                                    [
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs: {
                                                                            xs12:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm.selectedUser
                                                                            ? _c(
                                                                                "FullCalendar",
                                                                                {
                                                                                  ref:
                                                                                    "fullcalendar-" +
                                                                                    _vm.selectedUserId,
                                                                                  refInFor: true,
                                                                                  attrs: {
                                                                                    plugins:
                                                                                      _vm.calendarPlugins,
                                                                                    defaultDate:
                                                                                      _vm.defaultDate,
                                                                                    nowIndicator: true,
                                                                                    dragScroll: true,
                                                                                    selectable:
                                                                                      _vm.canEditCalendar,
                                                                                    selectMirror: true,
                                                                                    editable:
                                                                                      _vm.canEditCalendar,
                                                                                    slotDuration:
                                                                                      _vm.slotDuration,
                                                                                    columnsHeaderFormat:
                                                                                      _vm.columnsHeaderFormat,
                                                                                    slotLabelFormat:
                                                                                      _vm.slotLabelFormat,
                                                                                    eventOverlap: false,
                                                                                    selectOverlap:
                                                                                      _vm.canSelectOverlap,
                                                                                    allDaySlot: true,
                                                                                    allDayText:
                                                                                      "Mijn dossiers",
                                                                                    locale:
                                                                                      "nl",
                                                                                    header: true,
                                                                                    height: 900,
                                                                                    events:
                                                                                      _vm
                                                                                        .selectedUser
                                                                                        .events,
                                                                                    businessHours:
                                                                                      _vm
                                                                                        .selectedUser
                                                                                        .businessHours,
                                                                                    selectConstraint:
                                                                                      "businessHours",
                                                                                    eventConstraint:
                                                                                      "businessHours",
                                                                                    minTime:
                                                                                      "06:00",
                                                                                    maxTime:
                                                                                      "24:00",
                                                                                    firstDay: 1
                                                                                  },
                                                                                  on: {
                                                                                    select: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.createEvent(
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                    eventResize: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.updateEvent(
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                    eventClick: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.handleEventClick(
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                    eventDrop: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.updateEvent(
                                                                                        $event
                                                                                      )
                                                                                    },
                                                                                    eventRender:
                                                                                      _vm.renderEvent
                                                                                  }
                                                                                }
                                                                              )
                                                                            : _vm._e()
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isShowingEventDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "600px", persistent: true },
              model: {
                value: _vm.isShowingEventDialog,
                callback: function($$v) {
                  _vm.isShowingEventDialog = $$v
                },
                expression: "isShowingEventDialog"
              }
            },
            [
              _c("CreateEventDialog", {
                attrs: {
                  organization: _vm.activeOrganization,
                  user: _vm.selectedUserId,
                  type: _vm.selectedToggle,
                  event: _vm.selectedEvent,
                  start: _vm.selectedStartDate,
                  end: _vm.selectedEndDate
                },
                on: { eventCreatedOrUpdated: _vm.handleEventCreatedOrUpdated },
                model: {
                  value: _vm.isShowingEventDialog,
                  callback: function($$v) {
                    _vm.isShowingEventDialog = $$v
                  },
                  expression: "isShowingEventDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }