import { Event, Event as EventModel } from '@/models/Event';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { setFormattedDatePickerValue, isValidDate, dateErrorMessage } from '@/support/String';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { DateFormatter } from '@/support/DateFormatter';
import { User } from '@/models/User';
import { Rpc } from '@/models/Rpc';

@Component<CreateEventDialog>({})
export default class CreateEventDialog extends Vue {
  @Prop()
  protected event!: Event

  @Prop()
  protected organization!: string

  @Prop()
  protected user!: string

  @Prop()
  protected start!: DateTime

  @Prop()
  protected end!: DateTime

  @Prop()
  protected type!: string

  protected users: User[] = [];

  protected selectedUsers: string[] = [];

  protected locations = [];

  protected selectedLocation = '';

  protected isLoading = false;

  protected isEditingStartDate = false;

  protected isEditingEndDate = false;

  protected startDate = '';

  protected endDate = '';

  protected startTime = '';

  protected endTime = '';

  protected note = '';

  protected selectedType = ''

  // sequence edit
  protected sequenceProccesing = 'single';

  // repeat appointment
  protected repeatEndDate = new DateFormatter();

  protected repeatOption: string = RepeatOptionsEnum.NONE;

  protected conflictOption: string = ConflictOptionsEnum.STOP;

  protected repeatOptions = [
    {
      name: 'Niet herhalen',
      value: RepeatOptionsEnum.NONE,
    },
    {
      name: 'Dagelijks',
      value: RepeatOptionsEnum.DAILY,
    },
    {
      name: 'Wekelijks',
      value: RepeatOptionsEnum.WEEKLY,
    },
    {
      name: 'Tweewekelijks',
      value: RepeatOptionsEnum.BIWEEKLY,
    },
    {
      name: 'Maandelijks',
      value: RepeatOptionsEnum.MONTHLY,
    },
    {
      name: 'Jaarlijks',
      value: RepeatOptionsEnum.ANNUALLY,
    },
    {
      name: 'Elke werkdag (maandag t/m vrijdag',
      value: RepeatOptionsEnum.WEEKDAILY,
    },
  ];

  protected conflictOptions = [
    {
      name: 'Volledige reeks niet toevoegen',
      value: ConflictOptionsEnum.STOP,
    },
    {
      name: 'Reeks aanmaken en conflict(en) overslaan',
      value: ConflictOptionsEnum.SKIP,
    },
    {
      name: 'Reeks forceren',
      value: ConflictOptionsEnum.FORCE,
    },
  ];

  protected eventTypeLookup: {[key: string] : any} = {
    house_visit: {
      create: 'Huisbezoek afspraak inplannen',
      edit: 'Huisbezoek afspraak aanpassen',
    },
    speaking_room: {
      create: 'Spreekkamer dienst afspraak inplannen',
      edit: 'Spreekkamer dienst afspraak aanpassen',
    },
    meeting: {
      create: 'Overleg afspraak inplannen',
      edit: 'Overleg afspraak aanpassen',
    },
    community_center: {
      create: 'Steunpunt inplannen',
      edit: 'Steunpunt aanpassen',
    },
    holiday: {
      create: 'Verlof inplannen',
      edit: 'Verlof aanpassen',
    },
    sick: {
      create: 'Ziekte registreren',
      edit: 'Ziekte aanpassen',
    },
    other: {
      create: 'Overige afspraak inplannen',
      edit: 'Overige afspraak aanpassen',
    },
    loss_of_non_residential_property_value: {
      create: 'Waardedaling niet woningen inplannen',
      edit: 'Waardedaling niet woningen aanpassen',
    },
    manure_cellar: {
      create: 'Mestkelder inplannen',
      edit: 'Mestkelder aanpassen',
    },
  }

  protected async mounted(): Promise<void> {
    if (! this.event) {
      this.startDate = this.start ? this.start.toFormat('yyyy-LL-dd') : DateTime.local().toFormat('yyyy-LL-dd');
      this.endDate = this.end ? this.end.toFormat('yyyy-LL-dd') : DateTime.local().toFormat('yyyy-LL-dd');
      this.startTime = this.start ? this.start.toFormat('HH:mm') : DateTime.local().toFormat('HH:mm');
      this.endTime = this.end ? this.end.toFormat('HH:mm') : DateTime.local().toFormat('HH:mm');
      this.selectedType = this.type;
    } else {
      this.startDate = this.event.starts_at ? DateTime.fromSQL(this.event.starts_at).toFormat('yyyy-LL-dd') : '';
      this.endDate = this.event.ends_at ? DateTime.fromSQL(this.event.ends_at).toFormat('yyyy-LL-dd') : '';
      this.startTime = this.event.starts_at ? DateTime.fromSQL(this.event.starts_at).toFormat('HH:mm') : '';
      this.endTime = this.event.ends_at ? DateTime.fromSQL(this.event.ends_at).toFormat('HH:mm') : '';
      this.note = this.event.note || '';
      this.selectedType = this.event.type || '';
      this.selectedLocation = this.event.address?.uuid || '';
    }

    this.isLoading = true;
    await this.getUsers();
    await this.fetchAddresses();
    this.isLoading = false;
  }

  protected async fetchAddresses() {
    const response = await new Rpc()
      .rpcPost({ signature: 'planning:community-center:address:get', body: {} }, false);

    if (response) {
      this.locations = response.data;
    }
  }

  protected async getUsers(): Promise<void> {
    this.users = await new User()
      .dmz(this.organization)
      .sort('name', 'ASC')
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });

    // filter users
    this.users = this.users.filter((user: User) => user.uuid !== this.user);
  }

  protected createOrUpdate() {
    if (this.event) {
      this.updateEvent();
      return;
    }

    this.createEvent();
  }

  protected deleteEvent() {
    if (! this.event || ! this.event.id) {
      return;
    }

    this.isLoading = true;

    this.event
      .dmz(this.organization)
      .delete({
        sequence_proccesing: this.sequenceProccesing,
      })
      .then(() => {
        this.$emit('eventCreatedOrUpdated');
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected updateEvent() {
    const eventPayload: {[key: string]: string | string[]} = {
      starts_at: `${this.startDate} ${this.startTime}:00`,
      ends_at: `${this.endDate} ${this.endTime}:00`,
      note: this.note,
      address: this.selectedLocation,
    };

    if (this.selectedType !== 'community_center') {
      delete eventPayload.address;
    }

    if (this.sequenceProccesing) {
      eventPayload.sequence_proccesing = this.sequenceProccesing;
    }

    this.isLoading = true;

    new EventModel({ id: this.event.id })
      .dmz(this.organization)
      .update(eventPayload)
      .then((event: EventModel) => {
        this.$emit('eventCreatedOrUpdated', event);
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected createEvent() {
    const eventPayload: {[key: string]: string | string[]} = {
      type: this.type,
      organization: this.organization,
      user: this.user,
      starts_at: `${this.startDate} ${this.startTime}:00`,
      ends_at: `${this.endDate} ${this.endTime}:00`,
      note: this.note,
      address: this.selectedLocation,
    };

    if (this.selectedType !== 'community_center') {
      delete eventPayload.address;
    }

    if (this.repeatOption !== RepeatOptionsEnum.NONE) {
      eventPayload.pattern = this.repeatOption;
      eventPayload.sequence_conflict = this.conflictOption;
      eventPayload.sequence_end_date = this.repeatEndDate.selectedDate ? this.repeatEndDate.selectedDate : '';
    }

    eventPayload.users = [...this.selectedUsers, ...[this.user]];

    this.isLoading = true;

    new EventModel()
      .dmz(this.organization)
      .create(eventPayload)
      .then((event: EventModel) => {
        this.$emit('eventCreatedOrUpdated', event);
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  // DATE
  protected startdateFormatted: string | null = null;

  protected enddateFormatted: string | null = null;

  protected dateErrorMessage = '';

  protected dateFromDatePicker(type: string) {
    if (type === 'start') {
      this.startdateFormatted = setFormattedDatePickerValue(this.startDate, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.dateErrorMessage = ! isValidDate(this.startdateFormatted) ? dateErrorMessage : '';
    } else {
      this.enddateFormatted = setFormattedDatePickerValue(this.endDate, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.dateErrorMessage = ! isValidDate(this.enddateFormatted) ? dateErrorMessage : '';
    }
  }

  protected dateFromTextField(value: string, type: string) {
    this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    if (type === 'start') {
      this.startDate = setFormattedDatePickerValue(value);
    } else {
      this.endDate = setFormattedDatePickerValue(value);
    }
  }

  @Watch('startDate')
  protected startDateChanged() {
    this.dateFromDatePicker('start');
  }

  @Watch('endDate')
  protected endDateChanged() {
    this.dateFromDatePicker('end');
  }

  protected close() {
    this.$emit('input', false);
    this.reset();
  }

  private reset() {
    this.isLoading = true;
    this.isEditingStartDate = false;
    this.isEditingEndDate = false;
    this.startDate = '';
    this.endDate = '';
    this.startTime = '';
    this.endTime = '';
    this.note = '';
    this.selectedType = '';
  }

  protected confirm() {
    this.$emit('eventCreated');
  }

  protected get canSave(): boolean {
    if (this.event) {
      return true;
    }

    // You need a note before you can save.
    if (! this.note) {
      return false;
    }

    if (this.repeatOption === RepeatOptionsEnum.NONE) {
      return true;
    }

    if (! this.repeatEndDate?.formattedDate) {
      return false;
    }

    const eventDate = DateTime.fromSQL(this.endDate);
    const sequenceEndDate = DateTime.fromFormat(this.repeatEndDate.formattedDate, 'dd-LL-yyyy');

    return sequenceEndDate > eventDate;
  }
}

enum RepeatOptionsEnum {
  NONE = 'none',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
  WEEKDAILY = 'weekdaily',
}

enum ConflictOptionsEnum {
  STOP = 'stop',
  SKIP = 'skip',
  FORCE = 'force',
}
